export const forbiddenCountries = [
  'af',
  'cu',
  'ir',
  'iq',
  'lb',
  'ly',
  'kp',
  'so',
  'sy',
  'sd',
  've',
  'ye',
  // 'us',
];
